<template>
  <div class="pt-2">
    <div class="card">
      <AllTickets :items="items" @reload="loadTickets" />
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </div>
</template>
<script>
import ReportService from '@/services/ReportService'
import AllTickets from './AllTickets'
import cAlert from '@/helpers/alert'
import moment from '@/helpers/moment'

export default {
  name: 'RoundTickets',
  components: {
    AllTickets
  },
  data() {
    return {
      data: null,
      isLoading: false
    }
  },
  computed: {
    items() {
      return (this.data?.items || []).filter((item)=>{
        return item?.summary?.credit
      })
    }
  },
  methods: {
    loadTickets(round) {
      this.isLoading = true
      ReportService.getTicketWithStatus('wait').then((response) => {
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายการโพยไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.loadTickets()
  }
}
</script>
